import * as actionTypes from '../actions/actionTypes';

const initialState = {
  userDetails: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_START:
      return {
        ...state,
      };

    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        userDetails: action.userDetails,
      };

    case actionTypes.FETCH_USER_FAILED:
      return {
        ...state,
      };

    case actionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        userDetails: {},
      };

    default:
      return state;
  }
};

export default reducer;
