import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import * as authActions from '../../redux/actions';
import AuthInfo from './AuthInfo';

const AuthPage = (props) => {
  const {
    history, location, onFetchUserDetails, userDetails,
  } = props;
  useEffect(() => {
    if (userDetails && userDetails.role) {
      return history.replace('/dashboard');
    }
    const params = queryString.parse(location.search);
    if (params.token) {
      axios.defaults.headers.common.Authorization = `Bearer ${params.token}`;
      localStorage.setItem('lrea-auth-token', params.token);
      const fetchUserSync = async () => {
        await onFetchUserDetails();
      };
      fetchUserSync();
      return history.replace('/dashboard');
    }
    return history.push('/login');
  }, [history, location, userDetails, onFetchUserDetails]);
  return (
    <AuthInfo />
  );
};

AuthPage.defaultProps = {
  history: {},
};

AuthPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
  }),
  onFetchUserDetails: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onFetchUserDetails: () => dispatch(authActions.initFetchUser()),
});

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
