import { combineReducers } from 'redux';
import authUserReducer from './authUser.reducer';
import officeReducer from './office.reducer';
import groupReducer from './group.reducer';
import userReducer from './user.reducer';
import salesReducer from './sales.reducer';
import daReducer from './da.reducer';
import pmReducer from './pm.reducer';
import reportReducer from './report.reducer';

const rootReducer = combineReducers({
  auth: authUserReducer,
  office: officeReducer,
  group: groupReducer,
  user: userReducer,
  sales: salesReducer,
  da: daReducer,
  pm: pmReducer,
  report: reportReducer,
});

export default rootReducer;
