import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, NavLink, Redirect } from 'react-router-dom';
import Select from 'react-select';
import ProtectedRoute from '../../components/ProtectedRoute';
import Overview from './Overview';
import SalesPage from './SalesPage';
import PMPage from './PMPage';
import * as officeActions from '../../redux/actions';
import userRoles from '../../constants/user-roles';
import YoYPage from './YoY';
import MarketTracker from './MarketTracker';
import Test1 from './Test1';
import Test2 from './Test2';



const Dashboard = ({ userDetails, onFetchOfficesByUser }) => {
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let routes;
  if (userDetails && userRoles.superAdmin.includes(userDetails.role)) {
    routes = (
      <Switch>
        <ProtectedRoute path="/dashboard" exact allowedUser={userRoles.all}>
          <Overview office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/sales" exact allowedUser={userRoles.all}>
          <SalesPage office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/properties" exact allowedUser={userRoles.all}>
          <PMPage office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/yoy" exact allowedUser={userRoles.superAdmin}>
          <YoYPage office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/market-tracker" exact allowedUser={userRoles.superAdmin}>
          <MarketTracker office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/test1" exact allowedUser={userRoles.superAdmin}>
          <Test1 office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/test2" exact allowedUser={userRoles.superAdmin}>
          <Test2 office={selectedOffice} />
        </ProtectedRoute>
        <Redirect to="/dashboard" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <ProtectedRoute path="/dashboard" exact allowedUser={userRoles.all}>
          <Overview office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/sales" exact allowedUser={userRoles.all}>
          <SalesPage office={selectedOffice} />
        </ProtectedRoute>
        <ProtectedRoute path="/dashboard/properties" exact allowedUser={userRoles.all}>
          <PMPage office={selectedOffice} />
        </ProtectedRoute>
        <Redirect to="/dashboard" />
      </Switch>
    );
  }

  useEffect(() => {
    const fetchOfficesAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      setIsLoading(true);
      const offices = await onFetchOfficesByUser(pageOptions);
      if (offices && offices.length) {
        const options = offices.map((office) => ({
          key: office.id,
          label: office.name,
          value: office.id,
        }));
        setOfficeOptions(options);
        setSelectedOffice(options[0]);
      }
      setIsLoading(false);
    };
    if (!(isLoading || (selectedOffice && selectedOffice.value))) {
      fetchOfficesAsync();
    }
  }, [onFetchOfficesByUser, selectedOffice, isLoading]);

  const handleOfficeChange = (selectedOption) => {
    setSelectedOffice(selectedOption);
  };

  return (
    <div className="dashboard-container">
      <div className="links-section">
        <div className="dashboard-links">
          <NavLink to="/dashboard" exact activeClassName="active-link">Overview</NavLink>
          <NavLink to="/dashboard/sales" activeClassName="active-link">Sales Page</NavLink>
          <NavLink to="/dashboard/properties" activeClassName="active-link">PM Page</NavLink>
          { userDetails && userRoles.superAdmin.includes(userDetails.role) && (
            <>
              <NavLink to="/dashboard/yoy" activeClassName="active-link">YoY</NavLink>
              <NavLink to="/dashboard/market-tracker" activeClassName="active-link">Market Tracker</NavLink>
              <NavLink to="/dashboard/test1" activeClassName="active-link">Test 1</NavLink>
              <NavLink to="/dashboard/test2" activeClassName="active-link">REAP Index</NavLink>
            </>
          )}
        </div>
      
        <div className="office-select">

          <Select
            className="react-select-container"
            classNamePrefix="react-select"
            value={selectedOffice}
            options={officeOptions}
            onChange={handleOfficeChange}
            placeholder="Select User Role"
          />
        </div>
      </div>
      <div className="bi-container">
        {routes}
      </div>
    </div>
  );
};

Dashboard.defaultProps = {
  userDetails: null,
};

Dashboard.propTypes = {
  onFetchOfficesByUser: PropTypes.func.isRequired,
  userDetails: PropTypes.shape({
    role: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

const mapDispatchToProps = () => ({
  onFetchOfficesByUser: (paginationOptions) => officeActions.getOfficesByUser(paginationOptions),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
