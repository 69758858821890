/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';

export const initUploadFile = async (payload) => {
  try {
    await axios.post(`${config.SERVER_URI}/api/bulk-upload`, payload);
    toast.success(messages.bulkUploadSuccess);
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
  }
};
