import React from 'react';
import PropTypes from 'prop-types';

const UploadDownloadBtn = ({ children, clickHandler }) => (
  <button
    type="button"
    onClick={clickHandler}
    className="upload-download-btn"
  >
    {children}
  </button>
);

UploadDownloadBtn.defaultProps = {
  clickHandler: null,
};

UploadDownloadBtn.propTypes = {
  children: PropTypes.node.isRequired,
  clickHandler: PropTypes.func,
};

export default UploadDownloadBtn;
