import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as reportActions from '../../redux/actions';
import reportConfig from '../../config/reportConfig';

const MarketTrackerPage = ({ office, getReportDetails, marketTrackerReports }) => {
  const [errMessage, setErrMessage] = useState('');
  const previousOffice = useRef();
  useEffect(() => {
    const fetchReportAsync = async () => {
      if (office.value && (!errMessage || (office.value !== previousOffice.current))) {
        previousOffice.current = office.value;

        const reportDetails = marketTrackerReports;
        if (!reportDetails || (
          new Date().getTime() + 2 * 60 * 1000) > new Date(reportDetails.expiry).getTime()
        ) {
          await getReportDetails(office.value, 'market_tracker');
        } else {
          const reportContainer = document.getElementById('market-tracker-report');
          if (reportDetails && reportDetails.accessToken && reportDetails.embedUrl) {
            // Create a config object with Embed details and Token Type
            const reportLoadConfig = {
              accessToken: reportDetails.accessToken,
              embedUrl: `${reportDetails.embedUrl}'${office.value}'`,
              settings: reportConfig.settings,
              tokenType: reportConfig.tokenType,
              type: 'report',
            };

            // Embed Power BI report
            const report = window.powerbi.embed(reportContainer, reportLoadConfig);

            // Clear any other loaded handler events
            report.off('loaded');

            // Triggers when a report schema is successfully loaded
            // report.on('loaded', () => {
            //   console.log('Report loaded successfully.');
            // });

            // Clear any other rendered handler events
            report.off('rendered');

            // Triggers when a report is successfully embedded in UI
            // report.on('rendered', () => {
            //   console.log('Report rendered successfully.');
            // });

            // Clear any other error handler events
            report.off('error');

            // Handle embed errors
            report.on('error', () => {
              // console.error('Report Embed error: ');
            });
          } else {
            window.powerbi.reset(reportContainer);
            setErrMessage('Error while fetching Report !!');
          }
        }
      }
    };
    fetchReportAsync();
  }, [getReportDetails, office, errMessage, marketTrackerReports]);

  useEffect(() => () => {
    const reportContainer = document.getElementById('market-tracker-report');
    window.powerbi.reset(reportContainer);
  });

  return (
    <div className={((errMessage || !office.value) ? 'report-error-container' : 'market-tracker-container')}>
      <div id="market-tracker-report" className="market-tracker-report">
        Market Tracker report page ...
      </div>
      {
        (errMessage || !office.value) && (
          <div className="error-wrapper">
            <h4>
              {errMessage || 'Please select an Office.'}
            </h4>
          </div>
        )
      }
    </div>
  );
};

MarketTrackerPage.defaultProps = {
  marketTrackerReports: {},
};

MarketTrackerPage.propTypes = {
  office: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  getReportDetails: PropTypes.func.isRequired,
  marketTrackerReports: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  marketTrackerReports: state.report.market_tracker,
});

const mapDispatchToProps = (dispatch) => ({
  getReportDetails: (officeId, reportType) => dispatch(
    reportActions.getReportDetails(officeId, reportType),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketTrackerPage);
