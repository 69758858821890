import * as actionTypes from '../actions/actionTypes';

const initalData = {
  overview: null,
  sales: null,
  pm: null,
  yoy: null,
  market_tracker: null,
  test1: null,
  test2: null,
};

const reducer = (state = initalData, action) => {
  switch (action.type) {
    case actionTypes.STORE_REPORT_TOKEN:
      return {
        ...state,
        [action.reportType]: action.data,
      };

    case actionTypes.REMOVE_REPORT_TOKEN:
      return {
        ...state,
        overview: null,
        sales: null,
        pm: null,
        yoy: null,
        market_tracker: null,
        test1: null,
        test2: null,
      };

    default:
      return state;
  }
};

export default reducer;
