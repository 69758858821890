/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PageLoader from './PageLoader';

const ProtectedRoute = ({
  component: Component,
  allowedUser,
  userDetails,
  ...rest
}) => {
  const isAuthenticated = localStorage.getItem('lrea-auth-token');
  const isUserFetched = (userDetails && userDetails.role) ? (
    <Redirect
      to={{
        pathname: '/dashboard',
      }}
    />
  ) : (
    <PageLoader />
  );

  const isUserAllowed = (userDetails && allowedUser.includes(userDetails.role)) ? (
    <Component {...rest} />
  ) : isUserFetched;

  return (
    <Route
      {...rest}
      render={(props) => (
        isAuthenticated ? (
          isUserAllowed
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      )}
    />
  );
};

ProtectedRoute.defaultProps = {
  component: () => {},
  allowedUser: [],
  location: {},
  userDetails: null,
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.instanceOf(Object),
  allowedUser: PropTypes.instanceOf(Array),
  userDetails: PropTypes.shape({
    role: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

export default connect(mapStateToProps)(ProtectedRoute);
