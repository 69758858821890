/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { toast } from 'react-toastify';
import moment from 'moment';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import * as actions from '../../../redux/actions';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';

const EditDAData = (props) => {
  const { daId } = useParams();
  const {
    onFetchOfficeList,
    onEditDaData,
    onFetchDaDataById,
    history,
  } = props;

  const initalData = {
    period: '',
    gross_comm: 0,
    other_income: 0,
    cost_of_agents: 0,
    franchisee_fee: 0,
    staff_levies: 0,
    advert_consumables: 0,
    staff_consumables: 0,
    premises: 0,
    office_tech: 0,
    administration: 0,
    other_income_expense: 0,
  };

  const [daData, setDaData] = useState(initalData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedOfficeOption, setSelectedOfficeOption] = useState('');

  useEffect(() => {
    const fetchDaDataAsync = async () => {
      const da = await onFetchDaDataById(daId);
      const {
        id,
        created_date,
        updated_date,
        created_by,
        updated_by,
        ...restDaData
      } = da;
      setDaData({
        ...restDaData,
        period: moment(da.period).format('YYYY-MM-DD'),
      });
    };
    fetchDaDataAsync();
  }, [onFetchDaDataById, daId]);

  useEffect(() => {
    const fetchOfficesAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const options = offices && offices.map((office) => ({
        key: office.id,
        label: office.name,
        value: office.id,
      }));
      setOfficeOptions(options);
    };
    fetchOfficesAsync();
  }, [onFetchOfficeList]);

  useEffect(() => {
    const selectedOffice = officeOptions.find((office) => office.key === daData.office_id);
    setSelectedOfficeOption(selectedOffice);
  }, [daData.office_id, officeOptions]);

  const inputHandler = (e) => {
    if (e.target.name === 'period') {
      setDaData({
        ...daData,
        [e.target.name]: e.target.value,
      });
    } else {
      const pattern = /^[-+]?\d+(\.\d+)?$/;
      if (!e.target.value || pattern.test(e.target.value)) {
        setDaData({
          ...daData,
          [e.target.name]: e.target.value,
        });
      } else {
        setDaData({
          ...daData,
        });
      }
    }
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficeOption(selectedOptions);
  };

  const updateHandler = async (e) => {
    e.preventDefault();

    if (new Date(daData.period) > new Date()) {
      return toast.error('Please enter a valid date');
    }

    if (!selectedOfficeOption) {
      return toast.error('Please select an Office first');
    }

    const payload = {
      ...daData,
      office_id: selectedOfficeOption.value,
    };
    await onEditDaData(daId, payload);
    return history.push('/da');
  };

  return (
    <div className="form-wrapper">
      <h4>Edit DA Data</h4>
      <div className="form-container">
        <Form onSubmit={updateHandler}>
          <div className="fields-container">

            <Form.Group>
              <Form.Label>
                Office
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficeOption}
                options={officeOptions}
                onChange={handleOfficeChange}
                placeholder="Select Office"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Period
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="period"
                value={daData.period}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Gross Commission</Form.Label>
              <Form.Control
                type="number"
                name="gross_comm"
                value={daData.gross_comm}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Other Income</Form.Label>
              <Form.Control
                type="number"
                name="other_income"
                value={daData.other_income}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cost of Agents</Form.Label>
              <Form.Control
                type="number"
                name="cost_of_agents"
                value={daData.cost_of_agents}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Franchisee Fee</Form.Label>
              <Form.Control
                type="number"
                name="franchisee_fee"
                value={daData.franchisee_fee}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff Levies</Form.Label>
              <Form.Control
                type="number"
                name="staff_levies"
                value={daData.staff_levies}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Advert Consumables</Form.Label>
              <Form.Control
                type="number"
                name="advert_consumables"
                value={daData.advert_consumables}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff Consumables</Form.Label>
              <Form.Control
                type="number"
                name="staff_consumables"
                value={daData.staff_consumables}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Premises</Form.Label>
              <Form.Control
                type="number"
                name="premises"
                value={daData.premises}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Office Technology</Form.Label>
              <Form.Control
                type="number"
                name="office_tech"
                value={daData.office_tech}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Administration</Form.Label>
              <Form.Control
                type="number"
                name="administration"
                value={daData.administration}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Other Income Expense</Form.Label>
              <Form.Control
                type="number"
                name="other_income_expense"
                value={daData.other_income_expense}
                onChange={inputHandler}
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton
              onClickRoute={() => history.push('/da')}
            />
            <SubmitButton>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

EditDAData.defaultProps = {
  onFetchOfficeList: () => { },
  onEditDaData: () => { },
  onFetchDaDataById: () => { },
};

EditDAData.propTypes = {
  onFetchOfficeList: PropTypes.func,
  onEditDaData: PropTypes.func,
  onFetchDaDataById: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDisaptchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onEditDaData: (daId, payload) => dispatch(actions.initEditDaData(daId, payload)),
  onFetchDaDataById: (daId) => actions.getDaDataById(daId),
});

export default connect(null, mapDisaptchToProps)(withRouter(EditDAData));
