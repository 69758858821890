import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Form from 'react-bootstrap/Form';
// import Select from 'react-select';
import { toast } from 'react-toastify';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';
import * as actions from '../../../redux/actions';

const CreateOffice = ({ history, onAddOffice }) => {
  const initialData = {
    name: '',
    address: '',
  };

  const [officeData, setOfficeData] = useState(initialData);
  // const [groupOptions, setGroupOptions] = useState([]);
  // const [selectedGroup, setSelectedGroup] = useState('');

  // useEffect(() => {
  //   const fetchGroupsAsync = async () => {
  //     const pageOptions = {
  //       page: 0,
  //       size: 1000,
  //       order_by: 'name',
  //       ascending: true,
  //     };
  //     const groups = await onFetchGroupList(pageOptions);
  //     const optionsGroup = groups && groups.map((group) => ({
  //       key: group.id,
  //       label: group.name,
  //       value: group.id,
  //     }));
  //     setGroupOptions(optionsGroup);
  //   };
  //   fetchGroupsAsync();
  // }, [onFetchGroupList]);

  const inputHandler = (e) => {
    setOfficeData({
      ...officeData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleGroupChange = (selectedOption) => {
  //   setSelectedGroup(selectedOption);
  // };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (officeData.name.trim() === '') {
      return toast.error('Office Name cannot be empty!');
    }

    // if (!selectedGroup) {
    //   return toast.error('Please select a Group');
    // }

    // officeData.group_id = selectedGroup.value;
    await onAddOffice(officeData);
    return history.push('/offices');
  };

  return (
    <div className="form-wrapper">
      <h4>Create Office</h4>
      <div className="form-container">
        <Form onSubmit={submitHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>
                Name
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={officeData.name}
                name="name"
                placeholder="Office Name"
                onChange={inputHandler}
              />
            </Form.Group>
            {/* <Form.Group>
              <Form.Label>
                Group
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedGroup}
                options={groupOptions}
                onChange={handleGroupChange}
                placeholder="Select User Group"
              />
            </Form.Group> */}
            <Form.Group>
              <Form.Label>Office Address</Form.Label>
              <Form.Control
                as="textarea"
                value={officeData.address}
                rows="4"
                name="address"
                onChange={inputHandler}
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton onClickRoute={() => history.push('/offices')} />
            <SubmitButton>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

CreateOffice.defaultProps = {
  onAddOffice: () => { },
  // onFetchGroupList: () => { },
};

CreateOffice.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onAddOffice: PropTypes.func,
  // onFetchGroupList: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onAddOffice: (officeData) => dispatch(actions.initAddOffice(officeData)),
  // onFetchGroupList: (paginationOptions) => actions.getGroupList(paginationOptions),
});

export default connect(null, mapDispatchToProps)(withRouter(CreateOffice));
