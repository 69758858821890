/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '../../components/Table';
import ActionColumn from '../../components/ActionColumn';
import AddButton from '../../components/ActionButtons/AddButton';
import PageLoader from '../../components/PageLoader';
import * as actions from '../../redux/actions';

const Offices = (props) => {
  const {
    onFetchOffices,
    offices,
    history,
    onDeleteOffice,
    loading,
    // onFetchGroupList,
    totalSize,
  } = props;

  const initialPageOptions = {
    page: 1,
    size: 10,
    order_by: 'name',
    ascending: true,
  };

  const defaultSortDetails = {
    dataField: 'name',
    order: 'asc',
  };

  const [pageOptions, setPageOptions] = useState(initialPageOptions);
  const [sortDetails, setSortDetails] = useState(defaultSortDetails);
  // const [groupName, setGroupName] = useState({});

  useEffect(() => {
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchOffices(options);
  }, [onFetchOffices, pageOptions]);

  // useEffect(() => {
  //   const fetchGroupsAsync = async () => {
  //     const options = {
  //       page: 0,
  //       size: 1000,
  //       order_by: 'name',
  //       ascending: true,
  //     };
  //     const groupList = await onFetchGroupList(options);
  //     const groupNameIdMap = {};
  //     groupList.forEach((group) => {
  //       groupNameIdMap[group.id] = group.name;
  //     });
  //     setGroupName(groupNameIdMap);
  //   };
  //   fetchGroupsAsync();
  // }, [onFetchGroupList]);

  const sortCaretSign = (order) => {
    if (!order) return null;
    if (order === 'asc') {
      return (
        <span>
          <font>&#8593;</font>
        </span>
      );
    }
    if (order === 'desc') {
      return (
        <span>
          <font>&#8595;</font>
        </span>
      );
    }
    return null;
  };

  const deleteHandler = async (officeId) => {
    await onDeleteOffice(officeId);
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchOffices(options);
  };

  const editHandler = (officeId) => {
    history.push(`/edit-office/${officeId}`);
  };

  function rankFormatter(cell, row) {
    return (
      <ActionColumn
        editHandler={() => editHandler(row.id)}
        deleteHandler={() => deleteHandler(row.id)}
      />
    );
  }

  // const groupIdFormatter = (cell) => groupName[cell];

  const dataFormatter = (cell) => {
    if (cell === '') {
      return '- -';
    }
    return cell;
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Office Name',
      sort: true,
      sortCaret: (order) => sortCaretSign(order),
    },
    // {
    //   dataField: 'group_id',
    //   text: 'Group',
    //   sort: true,
    //   formatter: groupIdFormatter,
    //   sortCaret: (order) => sortCaretSign(order),
    // },
    {
      dataField: 'address',
      text: 'Address',
      sort: true,
      formatter: dataFormatter,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: rankFormatter,
      headerAttrs: { width: 100 },
      attrs: { width: 50, className: 'EditRow' },
    },
  ];

  const onClickRoute = () => {
    history.push('/add-office');
  };

  const handleTableChange = (
    type, {
      page,
      sizePerPage,
      sortOrder,
      sortField,
    },
  ) => {
    const options = {
      page,
      size: sizePerPage,
      order_by: sortField || 'name',
      ascending: (sortOrder === undefined || sortOrder === 'asc'),
    };
    if (
      pageOptions.page !== options.page
      || pageOptions.size !== options.size
      || pageOptions.order_by !== options.order_by
      || pageOptions.ascending !== options.ascending
    ) {
      setPageOptions(options);
      setSortDetails({
        dataField: sortField,
        order: sortOrder,
      });
    }
  };

  return (
    <div className="office-table-template">
      <div className="heading-row">
        <h4>Offices</h4>
        <AddButton
          onClickRoute={onClickRoute}
        >
          Add Office
        </AddButton>
      </div>
      {/* {groupName && Object.keys(groupName).length && ( */}
      {!loading ? (
        <Table
          sortDetails={sortDetails}
          data={offices}
          columns={columns}
          page={pageOptions.page}
          sizePerPage={pageOptions.size}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      ) : <PageLoader /> }
      {/* )} */}
    </div>
  );
};

Offices.defaultProps = {
  onFetchOffices: () => { },
  offices: [],
  onDeleteOffice: () => { },
  loading: false,
  // onFetchGroupList: () => { },
};

Offices.propTypes = {
  onFetchOffices: PropTypes.func,
  offices: PropTypes.instanceOf(Array),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  totalSize: PropTypes.number.isRequired,
  onDeleteOffice: PropTypes.func,
  loading: PropTypes.bool,
  // onFetchGroupList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  offices: state.office.officesList,
  totalSize: state.office.totalSize,
  loading: state.office.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchOffices: (paginationOptions) => dispatch(
    actions.initFetchOffices(paginationOptions),
  ),
  onDeleteOffice: (officeId) => dispatch(actions.initDeleteOffice(officeId)),
  // onFetchGroupList: (paginationOptions) => actions.getGroupList(paginationOptions),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Offices));
