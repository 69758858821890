/* eslint-disable no-case-declarations */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  groupsList: [],
  totalSize: 0,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GROUPS_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groupsList: action.groups,
        totalSize: action.totalSize,
      };

    case actionTypes.FETCH_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_GROUP_START:
      return {
        ...state,
      };

    case actionTypes.ADD_GROUP_SUCCESS:
      return {
        ...state,
        groupsList: [
          ...state.groupsList,
          action.addedGroup,
        ],
        totalSize: state.totalSize + 1,
      };

    case actionTypes.ADD_GROUP_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_GROUP_START:
      return {
        ...state,
      };

    case actionTypes.DELETE_GROUP_SUCCESS:
      const updatedGroupsList = state.groupsList.filter(
        (group) => group.id !== action.deletedGroupId,
      );
      return {
        ...state,
        groupsList: updatedGroupsList,
        totalSize: state.totalSize - 1,
      };

    case actionTypes.DELETE_GROUP_FAILED:
      return {
        ...state,
      };

    case actionTypes.EDIT_GROUP_START:
      return {
        ...state,
      };

    case actionTypes.EDIT_GROUP_SUCCESS:
      const updatedList = state.groupsList.map((group) => {
        if (group.id === action.groupId) {
          return action.updatedResp;
        }
        return group;
      });
      return {
        ...state,
        groupsList: updatedList,
      };

    case actionTypes.EDIT_GROUP_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
