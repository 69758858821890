/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import AddButton from '../../components/ActionButtons/AddButton';
import ActionColumn from '../../components/ActionColumn';
import Table from '../../components/Table';
import PageLoader from '../../components/PageLoader';
import * as userActions from '../../redux/actions';

const roleTypes = {
  BUSINESS_OWNER: 'Business Owner',
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
};

const Users = (props) => {
  const {
    usersList,
    totalSize,
    onFetchUsersList,
    onDeleteUser,
    loggedInUser,
    history,
    loading,
  } = props;

  const initialPageOptions = {
    page: 1,
    size: 10,
    order_by: 'name',
    ascending: true,
  };

  const defaultSortDetails = {
    dataField: 'name',
    order: 'asc',
  };

  const [pageOptions, setPageOptions] = useState(initialPageOptions);
  const [sortDetails, setSortDetails] = useState(defaultSortDetails);

  useEffect(() => {
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchUsersList(options);
  }, [onFetchUsersList, pageOptions]);

  const sortCaretSign = (order) => {
    if (!order) return null;
    if (order === 'asc') {
      return (
        <span>
          <font>&#8593;</font>
        </span>
      );
    }
    if (order === 'desc') {
      return (
        <span>
          <font>&#8595;</font>
        </span>
      );
    }
    return null;
  };

  const deleteHandler = async (userId) => {
    await onDeleteUser(userId);
    const options = {
      ...pageOptions,
      page: pageOptions.page - 1,
    };
    onFetchUsersList(options);
  };

  const editHandler = (userId) => {
    history.push(`/edit-user/${userId}`);
  };

  function rankFormatter(cell, row) {
    return (
      <ActionColumn
        disabled={loggedInUser && loggedInUser.id === row.id}
        editHandler={() => editHandler(row.id)}
        deleteHandler={() => deleteHandler(row.id)}
      />
    );
  }

  const roleFormatter = (cell, row) => roleTypes[row.role];

  const dataFormatter = (cell) => {
    if (cell === '') {
      return '- -';
    }
    return cell;
  };

  const columns = [
    {
      dataField: 'name',
      text: 'User Name',
      sort: true,
      formatter: dataFormatter,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'email',
      text: 'Email ID',
      sort: true,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'role',
      text: 'User Role',
      sort: true,
      formatter: roleFormatter,
      sortCaret: (order) => sortCaretSign(order),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: rankFormatter,
      headerAttrs: { width: 100 },
      attrs: { width: 50, className: 'EditRow' },
    },
  ];

  const onClickRoute = () => {
    history.push('/add-user');
  };

  const handleTableChange = (
    type, {
      page,
      sizePerPage,
      sortOrder,
      sortField,
    },
  ) => {
    const options = {
      page,
      size: sizePerPage,
      order_by: sortField || 'name',
      ascending: (sortOrder === undefined || sortOrder === 'asc'),
    };
    if (
      pageOptions.page !== options.page
      || pageOptions.size !== options.size
      || pageOptions.order_by !== options.order_by
      || pageOptions.ascending !== options.ascending
    ) {
      setPageOptions(options);
      setSortDetails({
        dataField: sortField,
        order: sortOrder,
      });
    }
  };

  return (
    <div className="user-table-template">
      <div className="heading-row">
        <h4>Users</h4>
        <AddButton
          onClickRoute={onClickRoute}
        >
          Add User
        </AddButton>
      </div>
      {!loading ? (
        <Table
          sortDetails={sortDetails}
          data={usersList}
          columns={columns}
          page={pageOptions.page}
          sizePerPage={pageOptions.size}
          totalSize={totalSize}
          onTableChange={handleTableChange}
        />
      ) : <PageLoader />}
    </div>
  );
};

Users.defaultProps = {
  usersList: [],
  loggedInUser: {},
  totalSize: 0,
  onFetchUsersList: () => { },
  onDeleteUser: () => { },
  loading: false,
};

Users.propTypes = {
  loggedInUser: PropTypes.object,
  usersList: PropTypes.array,
  totalSize: PropTypes.number,
  onFetchUsersList: PropTypes.func,
  onDeleteUser: PropTypes.func,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  loggedInUser: state.auth.userDetails,
  usersList: state.user.usersList,
  totalSize: state.user.totalSize,
  loading: state.user.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchUsersList: (paginationOptions) => dispatch(
    userActions.initFetchUserList(paginationOptions),
  ),
  onDeleteUser: (userId) => dispatch(userActions.initDeleteUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Users));
