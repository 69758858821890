import React, { useEffect, useState } from 'react';
import { useLocation, Redirect, Link } from 'react-router-dom';

const MissingComponent = () => {
  const location = useLocation();
  const [showComponent, setShowComponent] = useState(true);

  useEffect(() => {
    if (location.pathname === '/') {
      setShowComponent(false);
    }
  }, [location]);

  const component = (
    <main className="missing-wrapper">
      <div className="missing-text-wrapper">
        <h4>
          Page not found !
        </h4>
        <h5>
          Click here to go to
          {' '}
          {' '}
          <Link to="/dashboard">Home Page</Link>
        </h5>
      </div>
    </main>
  );
  return (
    <div className="missing-container">
      {showComponent ? component : <Redirect to="/dashboard" />}
    </div>
  );
};

export default MissingComponent;
