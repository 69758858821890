import React, { useEffect } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ToastContainer, Slide } from 'react-toastify';
import MissingComponent from './container/MissingComponent';
import AuthPage from './container/AuthPage';
import LoginPage from './container/LoginPage';
import Dashboard from './container/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import MenuBar from './container/MenuBar';
import Offices from './container/Offices';
import CreateOffice from './container/Offices/CreateOffice';
import EditOffice from './container/Offices/EditOffice';
import Users from './container/Users';
import CreateUser from './container/Users/CreateUser';
import EditUser from './container/Users/EditUser';
import Sales from './container/Sales';
import CreateSalesData from './container/Sales/CreateSalesData';
import EditSalesData from './container/Sales/EditSalesData';
import DA from './container/DA';
import CreateDAData from './container/DA/CreateDAData';
import EditDAData from './container/DA/EditDAData';
import PM from './container/PM';
import CreatePMData from './container/PM/CreatePMData';
import EditPMData from './container/PM/EditPMData';
import Groups from './container/Groups';
import CreateGroup from './container/Groups/CreateGroup';
import EditGroup from './container/Groups/EditGroup';
import BulkUpload from './container/BulkUpload';
import * as authActions from './redux/actions';
import AppInterceptors from './app-interceptors';
import userRoles from './constants/user-roles';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './scss/stylesheet.scss';

function App({ userDetails, onFetchUserDetails }) {
  AppInterceptors();
  const darkTheme = true;
  const defaultTheme = darkTheme ? 'theme-dark' : 'theme-light';
  const token = localStorage.getItem('lrea-auth-token');
  axios.defaults.headers.common['Cache-Control'] = 'no-cache';
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  useEffect(() => {
    onFetchUserDetails();
  }, [onFetchUserDetails]);

  const routes = (
    <Switch>
      <Route path="/login" exact component={LoginPage} />
      <Route path="/auth" exact component={AuthPage} />
      <ProtectedRoute path="/dashboard" allowedUser={userRoles.all} component={Dashboard} />

      {/* Office routes */}
      <ProtectedRoute path="/offices" exact allowedUser={userRoles.superAdmin} component={Offices} />
      <ProtectedRoute path="/add-office" exact allowedUser={userRoles.superAdmin} component={CreateOffice} />
      <ProtectedRoute path="/edit-office/:officeId" exact allowedUser={userRoles.superAdmin} component={EditOffice} />

      {/* User routes */}
      <ProtectedRoute path="/users" exact allowedUser={userRoles.superAdmin} component={Users} />
      <ProtectedRoute path="/add-user" exact allowedUser={userRoles.superAdmin} component={CreateUser} />
      <ProtectedRoute path="/edit-user/:userId" exact allowedUser={userRoles.superAdmin} component={EditUser} />

      {/* Sales routes */}
      <ProtectedRoute path="/sales" exact allowedUser={userRoles.superAdmin} component={Sales} />
      <ProtectedRoute path="/add-sales-data" exact allowedUser={userRoles.admins} component={CreateSalesData} />
      <ProtectedRoute path="/edit-sales-data/:salesId" exact allowedUser={userRoles.superAdmin} component={EditSalesData} />

      {/* DA routes */}
      <ProtectedRoute path="/da" exact allowedUser={userRoles.superAdmin} component={DA} />
      <ProtectedRoute path="/add-da-data" exact allowedUser={userRoles.admins} component={CreateDAData} />
      <ProtectedRoute path="/edit-da-data/:daId" exact allowedUser={userRoles.superAdmin} component={EditDAData} />

      {/* PM routes */}
      <ProtectedRoute path="/pm" exact allowedUser={userRoles.superAdmin} component={PM} />
      <ProtectedRoute path="/add-pm-data" exact allowedUser={userRoles.admins} component={CreatePMData} />
      <ProtectedRoute path="/edit-pm-data/:pmId" exact allowedUser={userRoles.superAdmin} component={EditPMData} />

      {/* Groups routes */}
      <ProtectedRoute path="/groups" exact allowedUser={userRoles.superAdmin} component={Groups} />
      <ProtectedRoute path="/add-group" exact allowedUser={userRoles.superAdmin} component={CreateGroup} />
      <ProtectedRoute path="/edit-group/:groupId" exact allowedUser={userRoles.superAdmin} component={EditGroup} />

      {/* Bulk Upload */}
      <ProtectedRoute path="/bulk-upload" exact allowedUser={userRoles.admins} component={BulkUpload} />

      {/* For unknown routes */}
      <Route component={MissingComponent} />
    </Switch>
  );

  return (
    <BrowserRouter>
      <div className={`${defaultTheme}`}>
        <div className="App">
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            newestOnTop
            transition={Slide}
          />
          {userDetails && userDetails.role && <MenuBar />}
          <main className="app-container">
            {routes}
          </main>
        </div>
      </div>
    </BrowserRouter>
  );
}

App.propTypes = {
  userDetails: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  onFetchUserDetails: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onFetchUserDetails: () => dispatch(authActions.initFetchUser()),
});

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
