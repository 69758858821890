import * as actionTypes from '../actions/actionTypes';

const initialState = {
  usersList: [],
  totalSize: 0,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_LIST_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FETCH_USER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersList: action.users,
        totalSize: action.totalSize,
      };

    case actionTypes.FETCH_USER_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_USER_START:
      return {
        ...state,
      };

    case actionTypes.ADD_USER_SUCCESS:
      return {
        ...state,
        usersList: [
          ...state.usersList,
          action.addedUser,
        ],
        totalSize: state.totalSize + 1,
      };

    case actionTypes.ADD_USER_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_USER_START:
      return {
        ...state,
      };

    case actionTypes.DELETE_USER_SUCCESS: {
      const updatedList = state.usersList.filter(
        (user) => user.id !== action.deletedUserId,
      );
      return {
        ...state,
        usersList: updatedList,
        totalSize: state.totalSize - 1,
      };
    }

    case actionTypes.DELETE_USER_FAILED:
      return {
        ...state,
      };

    case actionTypes.EDIT_USER_START:
      return {
        ...state,
      };

    case actionTypes.EDIT_USER_SUCCESS: {
      const updatedList = state.usersList.map((user) => {
        if (user.id === action.userId) {
          return action.updatedResp;
        }
        return user;
      });
      return {
        ...state,
        usersList: updatedList,
      };
    }

    case actionTypes.EDIT_USER_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
