import * as pbi from 'powerbi-client';

const { models } = pbi;
const reportConfig = {
  viewMode: models.ViewMode.View,
  permissions: models.Permissions.Read,
  tokenType: models.TokenType.Embed,
  settings: {
    panes: {
      bookmarks: {
        visible: false,
      },
      fields: {
        expanded: false,
        visible: false,
      },
      filters: {
        expanded: false,
        visible: false,
      },
      pageNavigation: {
        visible: false,
      },
      selection: {
        visible: false,
      },
      syncSlicers: {
        visible: false,
      },
      visualizations: {
        expanded: false,
        visible: false,
      },
    },
  },
};

export default reportConfig;
