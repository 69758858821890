import React from 'react';
import PropTypes from 'prop-types';

const AddButton = ({ children, onClickRoute }) => (
  <button
    type="button"
    onClick={onClickRoute}
    className="add-btn"
  >
    <span className="txt-sign-wrapper">
      <span className="plus-sign">+</span>
      <span>{children}</span>
    </span>
  </button>
);

AddButton.defaultProps = {
  children: 'Add New',
};

AddButton.propTypes = {
  onClickRoute: PropTypes.func.isRequired,
  children: PropTypes.string,
};

export default AddButton;
