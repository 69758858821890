import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import * as actions from '../../../redux/actions';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';

const CreatePMData = (props) => {
  const { onFetchOfficeList, onAddPmData, history } = props;

  const initalData = {
    period: '',
    management_fees: 0,
    lett_fees: 0,
    other_income: 0,
    cost_of_pm_managers: 0,
    franchisee_fee: 0,
    staff_levies: 0,
    advert_consumables: 0,
    staff_consumables: 0,
    premises: 0,
    office_tech: 0,
    administration: 0,
    other_income_expense: 0,
    senior_managers_count: 0,
    junior_managers_count: 0,
    business_dev_managers_count: 0,
    properties_managed_count: 0,
    gains_count: 0,
    losses_count: 0,
  };

  const [pmData, setPmData] = useState(initalData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedOfficeOption, setSelectedOfficeOption] = useState('');

  useEffect(() => {
    const fetchOfficesAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const options = offices && offices.map((office) => ({
        key: office.id,
        label: office.name,
        value: office.id,
      }));
      setOfficeOptions(options);
    };
    fetchOfficesAsync();
  }, [onFetchOfficeList]);

  const inputHandler = (e) => {
    if (e.target.name === 'period') {
      setPmData({
        ...pmData,
        [e.target.name]: e.target.value,
      });
    } else {
      const pattern = /^[-+]?\d+(\.\d+)?$/;
      if (!e.target.value || pattern.test(e.target.value)) {
        setPmData({
          ...pmData,
          [e.target.name]: e.target.value,
        });
      } else {
        setPmData({
          ...pmData,
        });
      }
    }
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficeOption(selectedOptions);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (new Date(pmData.period) > new Date()) {
      return toast.error('Please enter a valid date');
    }

    if (!selectedOfficeOption) {
      return toast.error('Please select an Office first');
    }
    const payload = {
      ...pmData,
      office_id: selectedOfficeOption.value,
    };
    await onAddPmData(payload);
    return history.push('/pm');
  };

  return (
    <div className="form-wrapper">
      <h4>Create PM Data</h4>
      <div className="form-container">
        <Form onSubmit={submitHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>
                Office
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficeOption}
                options={officeOptions}
                onChange={handleOfficeChange}
                placeholder="Select Office"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Period
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="period"
                value={pmData.period}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Management Fees</Form.Label>
              <Form.Control
                type="number"
                name="management_fees"
                value={pmData.management_fees}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Lett Fees</Form.Label>
              <Form.Control
                type="number"
                name="lett_fees"
                value={pmData.lett_fees}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Other Income</Form.Label>
              <Form.Control
                type="number"
                name="other_income"
                value={pmData.other_income}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cost of PM Managers</Form.Label>
              <Form.Control
                type="number"
                name="cost_of_pm_managers"
                value={pmData.cost_of_pm_managers}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Franchisee Fee</Form.Label>
              <Form.Control
                type="number"
                name="franchisee_fee"
                value={pmData.franchisee_fee}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff Levies</Form.Label>
              <Form.Control
                type="number"
                name="staff_levies"
                value={pmData.staff_levies}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Advert Consumables</Form.Label>
              <Form.Control
                type="number"
                name="advert_consumables"
                value={pmData.advert_consumables}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff Consumables</Form.Label>
              <Form.Control
                type="number"
                name="staff_consumables"
                value={pmData.staff_consumables}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Premises</Form.Label>
              <Form.Control
                type="number"
                name="premises"
                value={pmData.premises}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Office Technology</Form.Label>
              <Form.Control
                type="number"
                name="office_tech"
                value={pmData.office_tech}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Administration</Form.Label>
              <Form.Control
                type="number"
                name="administration"
                value={pmData.administration}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Other Income Expense</Form.Label>
              <Form.Control
                type="number"
                name="other_income_expense"
                value={pmData.other_income_expense}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Senior Manager Count</Form.Label>
              <Form.Control
                type="number"
                name="senior_managers_count"
                value={pmData.senior_managers_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Junior Manager Count</Form.Label>
              <Form.Control
                type="number"
                name="junior_managers_count"
                value={pmData.junior_managers_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Business Dev Managers Count</Form.Label>
              <Form.Control
                type="number"
                name="business_dev_managers_count"
                value={pmData.business_dev_managers_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Properties Managed Count</Form.Label>
              <Form.Control
                type="number"
                name="properties_managed_count"
                value={pmData.properties_managed_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Gains Count</Form.Label>
              <Form.Control
                type="number"
                name="gains_count"
                value={pmData.gains_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Losses Count</Form.Label>
              <Form.Control
                type="number"
                name="losses_count"
                value={pmData.losses_count}
                onChange={inputHandler}
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton
              onClickRoute={() => history.push('/pm')}
            />
            <SubmitButton>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

CreatePMData.defaultProps = {
  onFetchOfficeList: () => { },
  onAddPmData: () => { },
};

CreatePMData.propTypes = {
  onFetchOfficeList: PropTypes.func,
  onAddPmData: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDisaptchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onAddPmData: (payload) => dispatch(actions.initAddPmData(payload)),
});

export default connect(null, mapDisaptchToProps)(withRouter(CreatePMData));
