/* eslint-disable no-case-declarations */
import * as actionTypes from '../actions/actionTypes';

const initalData = {
  salesList: [],
  totalSize: 0,
  isLoading: false,
};

const reducer = (state = initalData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SALES_DATA_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FETCH_SALES_DATA_SUCCESS:
      return {
        ...state,
        salesList: action.salesData,
        totalSize: action.totalSize,
        isLoading: false,
      };

    case actionTypes.FETCH_SALES_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_SALES_DATA_START:
      return {
        ...state,
      };

    case actionTypes.ADD_SALES_DATA_SUCCESS:
      return {
        ...state,
        salesList: [
          ...state.salesList,
          action.addedSalesData,
        ],
        totalSize: state.totalSize + 1,
      };

    case actionTypes.ADD_SALES_DATA_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_SALES_DATA_START:
      return {
        ...state,
      };

    case actionTypes.DELETE_SALES_DATA_SUCCESS: {
      const updatedList = state.salesList.filter(
        (sales) => sales.id !== action.deletedSalesId,
      );
      return {
        ...state,
        salesList: updatedList,
        totalSize: state.totalSize - 1,
      };
    }

    case actionTypes.DELETE_SALES_DATA_FAILED:
      return {
        ...state,
      };

    case actionTypes.EDIT_SALES_DATA_START:
      return {
        ...state,
      };

    case actionTypes.EDIT_SALES_DATA_SUCCESS:
      const updatedList = state.salesList.map((sales) => {
        if (sales.id === action.salesId) {
          return action.updatedResp;
        }
        return sales;
      });
      return {
        ...state,
        salesList: updatedList,
      };

    case actionTypes.EDIT_SALES_DATA_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};
export default reducer;
