import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { MdCancel } from 'react-icons/md';
import { FaFileExcel } from 'react-icons/fa';
import { FiDownload, FiUpload } from 'react-icons/fi';
import { config } from '../../config';
import messages from '../../constants/messages';
import UploadDownloadBtn from '../../components/ActionButtons/UploadDownloadBtn';
import * as uploadActions from '../../redux/actions';

const BulkUpload = ({ onUploadFile }) => {
  const inputEl = useRef(null);
  const fileUpload = () => {
    inputEl.current.click();
  };

  const [uploadedFile, setUploadedFile] = useState(null);

  const handleChange = (e) => {
    const fileUploaded = (e.target.files.length && e.target.files[0]) || null;
    setUploadedFile(fileUploaded);
  };

  const removeFile = () => {
    setUploadedFile(null);
    inputEl.current.value = '';
  };

  const sampleFileDownloader = () => {
    toast.success(messages.downloadSampleFileMsg);
    window.location.assign(`${config.SERVER_URI}/LREA _ Bulk Upload Template.xlsx`);
  };

  const uploadFileHandler = () => {
    const formData = new FormData();
    formData.append('file', uploadedFile);
    onUploadFile(formData);
    setUploadedFile(null);
    inputEl.current.value = '';
  };

  return (
    <div className="upload-download-container">
      <h3>Bulk Upload</h3>
      <div className="action-btn-container">
        <UploadDownloadBtn clickHandler={sampleFileDownloader}>
          <FiDownload />
          <span>Download Sample</span>
        </UploadDownloadBtn>
        <div className="select-upload-wrapper">
          {uploadedFile
            && (
              <>
                <div className="text-action-container">
                  <span className="selected-file-name">{uploadedFile.name}</span>
                  <button
                    type="button"
                    title="Remove File"
                    className="remove-file"
                    onClick={removeFile}
                  >
                    <MdCancel size="20" />
                  </button>
                  <button
                    type="button"
                    title="Upload New"
                    className="upload-file"
                    onClick={fileUpload}
                  >
                    <FaFileExcel size="17" />
                  </button>
                </div>
                <UploadDownloadBtn clickHandler={uploadFileHandler}>
                  <FiUpload />
                  Upload
                </UploadDownloadBtn>
              </>
            )}
          {
            !uploadedFile
            && (
              <UploadDownloadBtn
                clickHandler={fileUpload}
              >
                Select File
              </UploadDownloadBtn>
            )
          }
        </div>
        <input
          type="file"
          name="file"
          accept=".xlsx, .xls"
          ref={inputEl}
          onChange={handleChange}
          hidden
        />
      </div>
    </div>
  );
};

BulkUpload.defaultProps = {
  onUploadFile: () => {},
};

BulkUpload.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onUploadFile: PropTypes.func,
};

const mapDispatchToProps = () => ({
  onUploadFile: (payload) => uploadActions.initUploadFile(payload),
});

export default connect(null, mapDispatchToProps)(withRouter(BulkUpload));
