import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import * as actions from '../../../redux/actions';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import CancelButton from '../../../components/ActionButtons/CancelButton';

const CreateSalesData = (props) => {
  const { onFetchOfficeList, onAddSalesData, history } = props;

  const initalData = {
    period: '',
    gross_comm: 0,
    other_income: 0,
    cost_of_agents: 0,
    franchisee_fee: 0,
    staff_levies: 0,
    advert_consumables: 0,
    staff_consumables: 0,
    premises: 0,
    office_tech: 0,
    administration: 0,
    other_income_expense: 0,
    agents_count: 0,
    prod_agents_teams_count: 0,
    pa_count: 0,
    directors_count: 0,
    prod_dirs_count: 0,
    dir_pa_count: 0,
    total_deals: 0,
    setteled_sales_count: 0,
    listings_count: 0,
  };

  const [salesData, setSalesData] = useState(initalData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedOfficeOption, setSelectedOfficeOption] = useState('');

  useEffect(() => {
    const fetchOfficesAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const options = offices && offices.map((office) => ({
        key: office.id,
        label: office.name,
        value: office.id,
      }));
      setOfficeOptions(options);
    };
    fetchOfficesAsync();
  }, [onFetchOfficeList]);

  const inputHandler = (e) => {
    if (e.target.name === 'period') {
      setSalesData({
        ...salesData,
        [e.target.name]: e.target.value,
      });
    } else {
      const pattern = /^[-+]?\d+(\.\d+)?$/;
      if (!e.target.value || pattern.test(e.target.value)) {
        setSalesData({
          ...salesData,
          [e.target.name]: e.target.value,
        });
      } else {
        setSalesData({
          ...salesData,
        });
      }
    }
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficeOption(selectedOptions);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (new Date(salesData.period) > new Date()) {
      return toast.error('Please enter a valid date');
    }

    if (!selectedOfficeOption) {
      return toast.error('Please select an Office first');
    }
    const payload = {
      ...salesData,
      office_id: selectedOfficeOption.value,
    };
    await onAddSalesData(payload);
    return history.push('/sales');
  };

  return (
    <div className="form-wrapper">
      <h4>Create Sales Data</h4>
      <div className="form-container">
        <Form onSubmit={submitHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>
                Office
                <span className="required-field"> *</span>
              </Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficeOption}
                options={officeOptions}
                onChange={handleOfficeChange}
                placeholder="Select Office"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Period
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="date"
                name="period"
                value={salesData.period}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Gross Commission</Form.Label>
              <Form.Control
                type="number"
                name="gross_comm"
                value={salesData.gross_comm}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Other Income</Form.Label>
              <Form.Control
                type="number"
                name="other_income"
                value={salesData.other_income}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cost of Agents</Form.Label>
              <Form.Control
                type="number"
                name="cost_of_agents"
                value={salesData.cost_of_agents}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Franchisee Fee</Form.Label>
              <Form.Control
                type="number"
                name="franchisee_fee"
                value={salesData.franchisee_fee}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff Levies</Form.Label>
              <Form.Control
                type="number"
                name="staff_levies"
                value={salesData.staff_levies}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Advert Consumables</Form.Label>
              <Form.Control
                type="number"
                name="advert_consumables"
                value={salesData.advert_consumables}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Staff Consumables</Form.Label>
              <Form.Control
                type="number"
                name="staff_consumables"
                value={salesData.staff_consumables}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Premises</Form.Label>
              <Form.Control
                type="number"
                name="premises"
                value={salesData.premises}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Office Technology</Form.Label>
              <Form.Control
                type="number"
                name="office_tech"
                value={salesData.office_tech}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Administration</Form.Label>
              <Form.Control
                type="number"
                name="administration"
                value={salesData.administration}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Other Income Expense</Form.Label>
              <Form.Control
                type="number"
                name="other_income_expense"
                value={salesData.other_income_expense}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>No. of Agents</Form.Label>
              <Form.Control
                type="number"
                name="agents_count"
                value={salesData.agents_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Production Agents Teams Count</Form.Label>
              <Form.Control
                type="number"
                name="prod_agents_teams_count"
                value={salesData.prod_agents_teams_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>PA Count</Form.Label>
              <Form.Control
                type="number"
                name="pa_count"
                value={salesData.pa_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>No. of Directors</Form.Label>
              <Form.Control
                type="number"
                name="directors_count"
                value={salesData.directors_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>No. of Production Directors</Form.Label>
              <Form.Control
                type="number"
                name="prod_dirs_count"
                value={salesData.prod_dirs_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Director PA Count</Form.Label>
              <Form.Control
                type="number"
                name="dir_pa_count"
                value={salesData.dir_pa_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Total Deals</Form.Label>
              <Form.Control
                type="number"
                name="total_deals"
                value={salesData.total_deals}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Settled Sales Count</Form.Label>
              <Form.Control
                type="number"
                name="setteled_sales_count"
                value={salesData.setteled_sales_count}
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>No. of Listings</Form.Label>
              <Form.Control
                type="number"
                name="listings_count"
                value={salesData.listings_count}
                onChange={inputHandler}
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton
              onClickRoute={() => history.push('/sales')}
            />
            <SubmitButton>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

CreateSalesData.defaultProps = {
  onFetchOfficeList: () => { },
  onAddSalesData: () => { },
};

CreateSalesData.propTypes = {
  onFetchOfficeList: PropTypes.func,
  onAddSalesData: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDisaptchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onAddSalesData: (payload) => dispatch(actions.initAddSalesData(payload)),
});

export default connect(null, mapDisaptchToProps)(withRouter(CreateSalesData));
