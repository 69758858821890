import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const fetchGroupsStart = () => ({
  type: actionTypes.FETCH_GROUPS_START,
});

const fetchGroupsSuccess = (responseData) => ({
  type: actionTypes.FETCH_GROUPS_SUCCESS,
  groups: responseData.content,
  totalSize: responseData.total_elements,
});

const fetchGroupsFailed = () => ({
  type: actionTypes.FETCH_GROUPS_FAILED,
});

export const initFetchGroups = (paginationOptions) => async (dispatch) => {
  try {
    dispatch(fetchGroupsStart());
    const resp = await axios.get(
      `${config.SERVER_URI}/api/groups`,
      { params: paginationOptions },
    );
    
    dispatch(fetchGroupsSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(fetchGroupsFailed());
  }
};

export const getGroupsList = async (paginationOptions) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/groups`, {
      params: paginationOptions,
    });
    return resp.data.content;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};

const addGroupStart = () => ({
  type: actionTypes.ADD_GROUP_START,
});

const addGroupSuccess = (addedGroup) => ({
  type: actionTypes.ADD_GROUP_SUCCESS,
  addedGroup,
});

const addGroupFailed = () => ({
  type: actionTypes.ADD_GROUP_FAILED,
});

export const initAddGroup = (groupData) => async (dispatch) => {
  try {
    dispatch(addGroupStart());
    const resp = await axios.post(`${config.SERVER_URI}/api/groups`, groupData);
    toast.success(messages.groupAddSuccess);
    dispatch(addGroupSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(addGroupFailed());
  }
};

const deleteGroupStart = () => ({
  type: actionTypes.DELETE_GROUP_START,
});

const deleteGroupSuccess = (deletedGroupId) => ({
  type: actionTypes.DELETE_GROUP_SUCCESS,
  deletedGroupId,
});

const deleteGroupFailed = () => ({
  type: actionTypes.DELETE_GROUP_FAILED,
});

export const initDeleteGroup = (groupId) => async (dispatch) => {
  try {
    dispatch(deleteGroupStart());
    await axios.delete(`${config.SERVER_URI}/api/groups/${groupId}`);
    toast.success(messages.groupDeleteSuccess);
    dispatch(deleteGroupSuccess(groupId));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(deleteGroupFailed());
  }
};

const editGroupStart = () => ({
  type: actionTypes.EDIT_GROUP_START,
});

const editGroupSuccess = (groupId, updatedResp) => ({
  type: actionTypes.EDIT_GROUP_SUCCESS,
  groupId,
  updatedResp,
});

const editGroupFailed = () => ({
  type: actionTypes.EDIT_GROUP_FAILED,
});

export const initEditGroup = (groupId, updatedGroupDetails) => async (dispatch) => {
  try {
    dispatch(editGroupStart());
    const resp = await axios.put(`${config.SERVER_URI}/api/groups/${groupId}`, updatedGroupDetails);
    toast.success(messages.groupUpdateSuccess);
    dispatch(editGroupSuccess(groupId, resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(editGroupFailed());
  }
};

export const getGroupById = async (groupId) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/groups/${groupId}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};
