import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as authAction from '../../redux/actions';
import Logout from '../../assets/logout.svg';

const LogoutButton = ({ onLogout }) => (
  <button
    className="logout-btn"
    type="button"
    onClick={() => onLogout()}
    title="Logout"
  >
    <img src={Logout} alt="Logout" />
  </button>
);

LogoutButton.defaultProps = {
  onLogout: () => { },
};

LogoutButton.propTypes = {
  onLogout: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(authAction.initUserLogout()),
});

export default connect(null, mapDispatchToProps)(LogoutButton);
