import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({ children }) => (
  <button
    type="submit"
    className="submit-update-btn"
  >
    {children}
  </button>
);

SubmitButton.defaultProps = {
  children: 'Submit',
};

SubmitButton.propTypes = {
  children: PropTypes.string,
};

export default SubmitButton;
