import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const fetchSalesDataStart = () => ({
  type: actionTypes.FETCH_SALES_DATA_START,
});

const fetchSalesDataSuccess = (responseData) => ({
  type: actionTypes.FETCH_SALES_DATA_SUCCESS,
  salesData: responseData.content,
  totalSize: responseData.total_elements,
});

const fetchSalesDataFailed = () => ({
  type: actionTypes.FETCH_SALES_DATA_FAILED,
});

export const initFetchSalesData = (paginationOptions) => async (dispatch) => {
  try {
    dispatch(fetchSalesDataStart());
    const resp = await axios.get(`${config.SERVER_URI}/api/sales-data`, {
      params: paginationOptions,
    });
    dispatch(fetchSalesDataSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(fetchSalesDataFailed());
  }
};

const addSalesDataStart = () => ({
  type: actionTypes.ADD_SALES_DATA_START,
});

const addSalesDataSuccess = (addedSalesData) => ({
  type: actionTypes.ADD_SALES_DATA_SUCCESS,
  addedSalesData,
});

const addSalesDataFailed = () => ({
  type: actionTypes.ADD_SALES_DATA_FAILED,
});

export const initAddSalesData = (payload) => async (dispatch) => {
  try {
    dispatch(addSalesDataStart());
    const resp = await axios.post(`${config.SERVER_URI}/api/sales-data`, payload);
    toast.success(messages.salesAddSuccess);
    dispatch(addSalesDataSuccess(resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(addSalesDataFailed());
  }
};

const deleteSalesDataStart = () => ({
  type: actionTypes.DELETE_SALES_DATA_START,
});

const deleteSalesDataSuccess = (deletedSalesId) => ({
  type: actionTypes.DELETE_SALES_DATA_SUCCESS,
  deletedSalesId,
});

const deleteSalesDataFailed = () => ({
  type: actionTypes.DELETE_SALES_DATA_FAILED,
});

export const initDeleteSalesData = (salesId) => async (dispatch) => {
  try {
    dispatch(deleteSalesDataStart());
    await axios.delete(`${config.SERVER_URI}/api/sales-data/${salesId}`);
    toast.success(messages.salesDeleteSuccess);
    dispatch(deleteSalesDataSuccess(salesId));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(deleteSalesDataFailed());
  }
};

const editSalesDataStart = () => ({
  type: actionTypes.EDIT_SALES_DATA_START,
});

const editSalesDataSuccess = (salesId, updatedResp) => ({
  type: actionTypes.EDIT_SALES_DATA_SUCCESS,
  salesId,
  updatedResp,
});

const editSalesDataFailed = () => ({
  type: actionTypes.EDIT_SALES_DATA_FAILED,
});

export const initEditSalesData = (salesId, payload) => async (dispatch) => {
  try {
    dispatch(editSalesDataStart());
    const resp = await axios.put(`${config.SERVER_URI}/api/sales-data/${salesId}`, payload);
    toast.success(messages.salesUpdateSuccess);
    dispatch(editSalesDataSuccess(salesId, resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    dispatch(editSalesDataFailed());
  }
};

export const getSalesDataById = async (salesId) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/sales-data/${salesId}`);
    return resp.data;
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
    return [];
  }
};
