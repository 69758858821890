/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { toast } from 'react-toastify';
import { config } from '../../config';
import messages from '../../constants/messages';
import * as actionTypes from './actionTypes';

const storeReportToken = (reportType, tokenData) => ({
  type: actionTypes.STORE_REPORT_TOKEN,
  reportType,
  data: tokenData,
});

export const getReportDetails = (officeId, reportType) => async (dispatch) => {
  try {
    const resp = await axios.get(`${config.SERVER_URI}/api/reports/${officeId}/${reportType}`);
    dispatch(storeReportToken(reportType, resp.data));
  } catch (err) {
    toast.error(err.response.data.message || messages.defaultErrorMsg);
  }
};
