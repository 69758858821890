import axios from 'axios';

const AppInterceptors = () => {
  axios.interceptors.response.use((response) => (response), (err) => {
    if (err.response.status === 401) {
      axios.defaults.headers.common.Authorization = '';
      localStorage.removeItem('lrea-auth-token');
      setTimeout(() => {
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }, 3000);
      return false;
    }
    if (err.response.status === 403) {
      setTimeout(() => {
        if (window.location.pathname !== '/dashboard') {
          window.location.href = '/dashboard';
        }
      }, 3000);
    }
    return Promise.reject(err);
  });
};

export default AppInterceptors;
