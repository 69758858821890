/* eslint-disable no-case-declarations */
import * as actionTypes from '../actions/actionTypes';

const initalData = {
  pmList: [],
  totalSize: 0,
  isLoading: false,
};

const reducer = (state = initalData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PM_DATA_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FETCH_PM_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pmList: action.pmData,
        totalSize: action.totalSize,
      };

    case actionTypes.FETCH_PM_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_PM_DATA_START:
      return {
        ...state,
      };

    case actionTypes.ADD_PM_DATA_SUCCESS:
      return {
        ...state,
        pmList: [
          ...state.pmList,
          action.addedPmData,
        ],
        totalSize: state.totalSize + 1,
      };

    case actionTypes.ADD_PM_DATA_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_PM_DATA_START:
      return {
        ...state,
      };

    case actionTypes.DELETE_PM_DATA_SUCCESS: {
      const updatedList = state.pmList.filter(
        (pmData) => pmData.id !== action.deletedPmId,
      );
      return {
        ...state,
        pmList: updatedList,
        totalSize: state.totalSize - 1,
      };
    }

    case actionTypes.DELETE_PM_DATA_FAILED:
      return {
        ...state,
      };

    case actionTypes.EDIT_PM_DATA_START:
      return {
        ...state,
      };

    case actionTypes.EDIT_PM_DATA_SUCCESS:
      const updatedList = state.pmList.map((pmData) => {
        if (pmData.id === action.pmId) {
          return action.updatedResponse;
        }
        return pmData;
      });
      return {
        ...state,
        pmList: updatedList,
      };

    case actionTypes.EDIT_PM_DATA_FAILED:
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducer;
