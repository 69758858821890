import React from 'react';
import PropTypes from 'prop-types';

const CancelButton = ({ onClickRoute }) => (
  <button
    type="button"
    className="cancel-btn"
    onClick={() => { onClickRoute(); }}
  >
    Cancel
  </button>
);

CancelButton.propTypes = {
  onClickRoute: PropTypes.func.isRequired,
};

export default CancelButton;
