/* eslint-disable no-case-declarations */
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  officesList: [],
  totalSize: 0,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OFFICES_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FETCH_OFFICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        officesList: action.offices,
        totalSize: action.totalSize,
      };

    case actionTypes.FETCH_OFFICES_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_OFFICE_START:
      return {
        ...state,
      };

    case actionTypes.ADD_OFFICE_SUCCESS:
      return {
        ...state,
        officesList: [
          ...state.officesList,
          action.addedOffice,
        ],
        totalSize: state.totalSize + 1,
      };

    case actionTypes.ADD_OFFICE_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_OFFICE_START:
      return {
        ...state,
      };

    case actionTypes.DELETE_OFFICE_SUCCESS:
      const updatedOfficeList = state.officesList.filter(
        (office) => office.id !== action.deletedOfficeId,
      );
      return {
        ...state,
        officesList: updatedOfficeList,
        totalSize: state.totalSize - 1,
      };

    case actionTypes.DELETE_OFFICE_FAILED:
      return {
        ...state,
      };

    case actionTypes.EDIT_OFFICE_START:
      return {
        ...state,
      };

    case actionTypes.EDIT_OFFICE_SUCCESS:
      const updatedList = state.officesList.map((office) => {
        if (office.id === action.officeId) {
          return action.updatedResp;
        }
        return office;
      });
      return {
        ...state,
        officesList: updatedList,
      };

    case actionTypes.EDIT_OFFICE_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
