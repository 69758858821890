import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import LiveLogo from '../../assets/lrea-logo.svg';

const AuthInfo = () => (
  <div className="auth-info-container">
    <main className="auth-info-wrapper">
      <img src={LiveLogo} alt="LREA-Logo" className="live-logo" />
      <Loader
        type="ThreeDots"
        color="#00BFFF"
        visible
        height={100}
        width={100}
      />
      <h4>Please wait....</h4>

    </main>
  </div>
);

export default AuthInfo;
