/* eslint-disable no-case-declarations */
import * as actionTypes from '../actions/actionTypes';

const initalData = {
  daList: [],
  totalSize: 0,
  isLoading: false,
};

const reducer = (state = initalData, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DA_DATA_START:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.FETCH_DA_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        daList: action.daData,
        totalSize: action.totalSize,
      };

    case actionTypes.FETCH_DA_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case actionTypes.ADD_DA_DATA_START:
      return {
        ...state,
      };

    case actionTypes.ADD_DA_DATA_SUCCESS:
      return {
        ...state,
        daList: [
          ...state.daList,
          action.addedDaData,
        ],
        totalSize: state.totalSize + 1,
      };

    case actionTypes.ADD_DA_DATA_FAILED:
      return {
        ...state,
      };

    case actionTypes.DELETE_DA_DATA_START:
      return {
        ...state,
      };

    case actionTypes.DELETE_DA_DATA_SUCCESS: {
      const updatedList = state.daList.filter(
        (daData) => daData.id !== action.deletedDaId,
      );
      return {
        ...state,
        daList: updatedList,
        totalSize: state.totalSize - 1,
      };
    }

    case actionTypes.DELETE_DA_DATA_FAILED:
      return {
        ...state,
      };

    case actionTypes.EDIT_DA_DATA_START:
      return {
        ...state,
      };

    case actionTypes.EDIT_DA_DATA_SUCCESS:
      const updatedList = state.daList.map((daData) => {
        if (daData.id === action.daId) {
          return action.updatedResponse;
        }
        return daData;
      });
      return {
        ...state,
        daList: updatedList,
      };

    case actionTypes.EDIT_DA_DATA_FAILED:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default reducer;
