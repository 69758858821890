import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import CancelButton from '../../../components/ActionButtons/CancelButton';
import SubmitButton from '../../../components/ActionButtons/SubmitButton';
import * as actions from '../../../redux/actions';

const EditGroup = (props) => {
  const {
    onFetchOfficeList,
    history,
    onFetchGroupById,
    onEditGroup,
  } = props;

  const { groupId } = useParams();

  const initialData = {
    name: '',
  };

  const [groupData, setGroupData] = useState(initialData);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [selectedOfficesOptions, setSelectedOfficesOptions] = useState([]);

  useEffect(() => {
    const fetchDataAsync = async () => {
      const pageOptions = {
        page: 0,
        size: 1000,
        order_by: 'name',
        ascending: true,
      };
      const offices = await onFetchOfficeList(pageOptions);
      const optionsOffice = offices && offices.map((office) => ({
        key: office.id,
        label: office.name,
        value: office.id,
      }));

      setOfficeOptions(optionsOffice);
    };
    fetchDataAsync();
  }, [onFetchOfficeList]);

  useEffect(() => {
    const fetchGroupAsync = async () => {
      if (groupId) {
        const group = await onFetchGroupById(groupId);
        setGroupData({
          name: group.name,
          offices: group.offices,
        });
      }
    };
    fetchGroupAsync();
  }, [groupId, onFetchGroupById]);

  // *useEffect* for showing the selected options.
  useEffect(() => {
    if (!(officeOptions && groupData)) {
      return;
    }
    const offices = [];
    officeOptions.forEach((office) => {
      if (groupData && groupData.offices && groupData.offices.includes(office.value)) {
        offices.push(office);
      }
    });
    setSelectedOfficesOptions(offices);

    // const userGroup = groupOptions.find((option) => option.value === groupData.group_id);
    // setSelectedGroup(userGroup);
  }, [officeOptions, groupData]);

  const inputHandler = (e) => {
    setGroupData({
      ...groupData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOfficeChange = (selectedOptions) => {
    setSelectedOfficesOptions(selectedOptions);
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    if (groupData.name.trim() === '') {
      return toast.error('Group Name cannot be empty!');
    }

    const payload = {
      ...groupData,
      offices: selectedOfficesOptions && selectedOfficesOptions.map((options) => options.value),
    };

    await onEditGroup(groupId, payload);
    return history.push('/groups');
  };

  return (
    <div className="form-wrapper">
      <h4>Create Group</h4>
      <div className="form-container">
        <Form onSubmit={updateHandler}>
          <div className="fields-container">
            <Form.Group>
              <Form.Label>
                Name
                <span className="required-field"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                value={groupData.name}
                name="name"
                placeholder="Group Name"
                onChange={inputHandler}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Offices</Form.Label>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={selectedOfficesOptions}
                options={officeOptions}
                onChange={handleOfficeChange}
                isMulti
                placeholder="Select User Offices"
              />
            </Form.Group>
          </div>
          <div className="action-btn-wrapper">
            <CancelButton onClickRoute={() => history.push('/groups')} />
            <SubmitButton>Submit</SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  );
};

EditGroup.defaultProps = {
  onFetchOfficeList: () => {},
  onFetchGroupById: () => {},
  onEditGroup: () => {},
};

EditGroup.propTypes = {
  onFetchOfficeList: PropTypes.func,
  onFetchGroupById: PropTypes.func,
  onEditGroup: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onFetchOfficeList: (paginationOptions) => actions.getOfficeList(paginationOptions),
  onFetchGroupById: (groupId) => actions.getGroupById(groupId),
  onEditGroup: (groupId, payload) => dispatch(actions.initEditGroup(groupId, payload)),
});

export default connect(null, mapDispatchToProps)(withRouter(EditGroup));
