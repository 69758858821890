import React from 'react';
import PropTypes from 'prop-types';
import { MdEdit } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';

const ActionColumn = ({
  editHandler,
  deleteHandler,
  disabled,
}) => (
  <div className="action-cell">
    <button
      type="button"
      title="Edit"
      className="edit-btn"
      onClick={() => editHandler()}
      disabled={disabled}
    >
      <MdEdit size={20} />
    </button>
    <button
      type="button"
      title="Delete"
      onClick={() => deleteHandler()}
      className="delete-btn"
      disabled={disabled}
    >
      <TiDelete size={24} />
    </button>
  </div>
);

ActionColumn.defaultProps = {
  disabled: false,
};

ActionColumn.propTypes = {
  editHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default ActionColumn;
