const messages = {
  defaultErrorMsg: 'An unexpected error has occurred!',
  downloadSampleFileMsg: 'Downloading Sample Template file..',

  groupAddSuccess: 'Group added successfully.',
  officeAddSuccess: 'Office added successfully.',
  userAddSuccess: 'User added successfully.',

  groupDeleteSuccess: 'Group deleted successfully.',
  officeDeleteSuccess: 'Office deleted successfully.',
  userDeleteSuccess: 'User deleted successfully.',

  groupUpdateSuccess: 'Group updated successfully.',
  officeUpdateSuccess: 'Office updated successfully.',
  userUpdateSuccess: 'User updated successfully.',

  salesAddSuccess: 'Sales Data added successfully.',
  daAddSucces: 'DA Data added successfully',
  pmAddSucces: 'PM Data added successfully',

  salesDeleteSuccess: 'Sales Data deleted successfully',
  daDeleteSuccess: 'DA Data deleted successfully',
  pmDeleteSuccess: 'PM Data deleted successfully',

  salesUpdateSuccess: 'Sales Data updated successfully',
  daUpdateSuccess: 'DA Data updated successfully',
  pmUpdateSuccess: 'PM Data updated successfully',

  bulkUploadSuccess: 'Bulk Data uploaded successfully',
};

export default messages;
