import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const PageLoader = () => (
  <div className="loader-container">
    <Loader
      type="ThreeDots"
      color="#00BFFF"
      visible
      height={100}
      width={100}
    />
  </div>
);

export default PageLoader;
