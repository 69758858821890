import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoutButton from '../../components/LogoutButton';
import menuHamburger from '../../assets/hamburger.svg';
import LiveLogoWhite from '../../assets/logo-white.svg';
import LoginPlaceholder from '../../assets/login-placeholder.svg';

const MenuBar = ({ userDetails }) => {
  const imageErrorHandler = (e) => {
    e.target.src = LoginPlaceholder;
  };

  return (
    <nav className="menu-bar">
      <div className="menu-icon-wrapper">
        {userDetails
          && (userDetails.role === 'SUPER_ADMIN' || userDetails.role === 'ADMIN')
          && (
            <span className="dropdown">
              <img src={menuHamburger} alt="Menu-Button" className="menu-hamburger" />
              {userDetails.role === 'SUPER_ADMIN' ? (
                <div className="dropdown-content">
                  <Link to="/groups">Group List</Link>
                  <Link to="/offices">Office List</Link>
                  <Link to="/users">User List</Link>
                  <Link to="/sales">Sales Data</Link>
                  <Link to="/da">DA Data</Link>
                  <Link to="/pm">PM Data</Link>
                  <Link to="/bulk-upload">Bulk Upload</Link>
                </div>
              ) : (
                <div className="dropdown-content">
                  <Link to="/add-sales-data">Create Sales Data</Link>
                  <Link to="/add-da-data">Create DA Data</Link>
                  <Link to="/add-pm-data">Create PM Data</Link>
                  <Link to="/bulk-upload">Bulk Upload</Link>
                </div>
              )}
            </span>
          )}
      </div>
      <Link to="/dashboard">
        <div className="reap-logo-heading-wrapper">
          <img className="reap-live-logo" src={LiveLogoWhite} alt="REAP Live CFO" />
          <span className="reap-live-heading">REAP Dashboard</span>
        </div>
      </Link>
      {userDetails && userDetails.role
        && (
          <div className="login-btn-wrapper">
            <img
              src={userDetails.picture || LoginPlaceholder}
              alt="Profile Pic"
              className="profile-image"
              onError={imageErrorHandler}
            />
            <span className="user-name">
              {userDetails.name}
            </span>
            <LogoutButton />
          </div>
        )}
    </nav>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state.auth.userDetails,
});

MenuBar.propTypes = {
  userDetails: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

export default connect(mapStateToProps)(MenuBar);
